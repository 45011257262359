<main class="main">
  <div class="content">
    <div class="left-side">
      <h1>
        <a routerLink="/">{{ title }}</a>
        <button mat-icon-button [matMenuTriggerFor]="appMenu" aria="menu"><mat-icon>menu</mat-icon></button>
      </h1>
      <mat-menu #appMenu="matMenu">
        <a mat-menu-item routerLink="/"><mat-icon>home</mat-icon> Home</a>
        @if (loggedIn) {
          <a mat-menu-item routerLink="/profile"><mat-icon>account_circle</mat-icon> Profile</a>
          <a mat-menu-item routerLink="/subscription"><mat-icon>credit_card</mat-icon> Subscription</a>
          <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
        }
      </mat-menu>
      @if (loggedIn) {
        <app-chat [user]="user" />
      } @else {
        <mat-tab-group>
          <mat-tab label="Login">
            <app-loginform></app-loginform>
          </mat-tab>
          <mat-tab label="Register">
            <app-registerform></app-registerform>
          </mat-tab>
        </mat-tab-group>
        <app-facebook-login [loggedIn]="loggedIn"></app-facebook-login>
      }
    </div>
    <div class="divider" role="separator" aria-label="Divider"></div>
    <div class="right-side">
      <router-outlet />
    </div>
  </div>
</main>
